exports.shouldUpdateScroll = ({
    routerProps: { location }
}) => {
    //Scroll to top if pathname is of Home:
    if (location.hash === '') {
        window.scrollTo(0, 0);
    }
    else{
        let element = document.getElementById(location.hash.replace('#', ''));
        if(element !== null){
            setTimeout(() => {
                element?.scrollIntoView(true);
            }, 500)
        }
        else{
            window.scrollTo(0, 0);
        }
    }

    return false
}